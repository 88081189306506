enum GAME_TYPE {
  KNIGHTCORE = 'knightcore',
  YGGDRASIL = 'yggdrasil',
  SAINT_SEIYA = 'saint_seiya',
  BLEACH = 'bleach',
  NOCTUA = 'noctua',
  AFK_CHAMPION = 'afk_champion'
}

export const GAME_OPTIONS = [
  GAME_TYPE.KNIGHTCORE,
  GAME_TYPE.YGGDRASIL,
  GAME_TYPE.SAINT_SEIYA,
  GAME_TYPE.BLEACH,
  GAME_TYPE.NOCTUA,
  GAME_TYPE.AFK_CHAMPION
]

export default GAME_TYPE
