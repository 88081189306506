/* eslint-disable react-hooks/exhaustive-deps */
/*eslint prefer-const: 0*/
import { useHistory, useParams } from 'react-router-dom'
import Layout from '../../components/layout'
import RoutesLabel from '../../constants/enum/route-name'
import React, { useEffect, useState } from 'react'
import DefaultForm from '../../components/form'
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  Space,
  Typography
} from 'antd'
import { ReduxState } from '../../store/reducers'
import { connect } from 'react-redux'
import Actions from '../../store/actions'
import UPLOAD_SERVICE from '../../constants/upload_service'
import UPLOAD_KIND from '../../constants/upload-kind'
import IUploadRequest from '../../interfaces/request/upload-request'
import IUploadFile from '../../interfaces/models/upload-file'
import { uploadPromise } from '../../utils'
import ILauncher, { ILauncherContent } from '../../interfaces/models/launcher'
import ILauncherState from '../../interfaces/states/launcher'
import LauncherFormField from '../../constants/form/launcher'
import LauncherList from './list'
import moment from 'moment'
import { IResponse } from '@attn/general/dist/interfaces/api'
import IAuthState from '../../interfaces/states/auth'
import ButtonAction from '../../components/button-action'
import USER_ACTION from '../../constants/user-action'
import { CloseOutlined } from '@ant-design/icons'

interface IProps {
  auth: IAuthState
  launcher: ILauncherState
  Upload: (data: IUploadRequest) => Promise<any>
  GetDetail: (id: number) => Promise<any>
  Update: (data: ILauncher) => Promise<any>
  Create: (data: ILauncher) => Promise<any>
  GetList: (
    gameCode?: string,
    countryCode?: string,
    page?: number,
    limit?: number
  ) => Promise<any>
  Delete: (id: number) => Promise<any>
  UpdateContent: (id: string, data: ILauncherContent) => Promise<any>
}

const LauncherPage = (props: IProps) => {
  const {
    auth,
    launcher,
    Create,
    Upload,
    GetDetail,
    GetList,
    Delete,
    Update,
    UpdateContent
  } = props
  const { id }: any = useParams()
  const [loading, setLoading] = useState<boolean>(false)
  const history = useHistory()
  const data = launcher.data

  useEffect(() => {
    GetList('', '', 1, 10)
  }, [])

  useEffect(() => {
    if (parseInt(id) && id) GetDetail(id)
  }, [id])

  const NewsArrToObj = (news: any[]) => {
    if (news.length === 0) {
      return { news: {} }
    }

    return news.reduce((acc, item) => {
      acc[item.name] = item.list && item.list.length ? item.list : []
      return acc
    }, {})
  }

  const NewsObjToArr = (news: any) => {
    if (Object.keys(news).length === 0) {
      return { news: [] }
    }

    return Object.keys(news).map((key) => ({
      name: key,
      list: news[key].length
        ? news[key].map((x: any) => ({
            ...x,
            crtime: moment(x.crtime) as any
          }))
        : []
    }))
  }

  const onSubmit = async (value) => {
    setLoading(true)
    let submitData = Object.assign(value)

    //upload launcherImage
    if (typeof value?.content?.launcherImage?.[0] === 'object') {
      const { url } = await uploadPromise(
        'launcherImage',
        {
          files: value?.content?.launcherImage?.[0],
          names: value?.content?.launcherImage?.[0]?.name,
          service: UPLOAD_SERVICE.GENERAL,
          serviceKind: UPLOAD_KIND.IMAGE
        },
        Upload
      )
      submitData.content.launcherImage = url
    }

    //upload educationalVideoImage
    if (typeof value?.content?.educationalVideoImage?.[0] === 'object') {
      const { url } = await uploadPromise(
        'educationalVideoImage',
        {
          files: value?.content?.educationalVideoImage?.[0],
          names: value?.content?.educationalVideoImage?.[0]?.name,
          service: UPLOAD_SERVICE.GENERAL,
          serviceKind: UPLOAD_KIND.IMAGE
        },
        Upload
      )
      submitData.content.educationalVideoImage = url
    }

    //upload announce.image
    if (typeof value?.content?.announce?.img?.[0] === 'object') {
      const { url } = await uploadPromise(
        'announceImage',
        {
          files: value?.content?.announce?.img?.[0],
          names: value?.content?.announce?.img?.[0]?.name,
          service: UPLOAD_SERVICE.GENERAL,
          serviceKind: UPLOAD_KIND.IMAGE
        },
        Upload
      )
      submitData.content.announce.img = url
    }

    //upload announce.video.image as announce video thumbnail
    if (typeof value?.content?.announce?.video?.img?.[0] === 'object') {
      const { url } = await uploadPromise(
        'announceThumbnail',
        {
          files: value?.content?.announce?.video?.img?.[0],
          names: value?.content?.announce?.video?.img?.[0]?.name,
          service: UPLOAD_SERVICE.GENERAL,
          serviceKind: UPLOAD_KIND.IMAGE
        },
        Upload
      )
      submitData.content.announce.video.img = url
    }

    // upload image social medias
    const socmeds = value?.content.socialMedias
    const uploadIconPromises: Array<Promise<IUploadFile>> = []
    for (const key in socmeds) {
      if (typeof socmeds[key].img?.[0] === 'object') {
        const data = {
          files: socmeds[key].img?.[0],
          names: socmeds[key].img?.[0]?.name,
          service: UPLOAD_SERVICE.GENERAL,
          serviceKind: UPLOAD_KIND.IMAGE
        } as IUploadRequest
        uploadIconPromises.push(uploadPromise(key, data, Upload))
      }
    }
    const uploadIconUrls =
      (await Promise.all(uploadIconPromises).catch(() => {})) || []
    for (const uploadIconUrl of uploadIconUrls) {
      const { name, url } = uploadIconUrl
      submitData.content.socialMedias[name].img = url
    }

    // upload image social banners
    const banners = value?.content.banners
    const uploadImagePromises: Array<Promise<IUploadFile>> = []
    for (const key in banners) {
      if (typeof banners[key].img[0] === 'object') {
        const data = {
          files: banners[key].img?.[0],
          names: banners[key].img?.[0]?.name,
          service: UPLOAD_SERVICE.GENERAL,
          serviceKind: UPLOAD_KIND.IMAGE
        } as IUploadRequest
        uploadImagePromises.push(uploadPromise(key, data, Upload))
      }
    }
    const uploadImageUrls =
      (await Promise.all(uploadImagePromises).catch(() => {})) || []
    for (const uploadImageUrl of uploadImageUrls) {
      const { name, url } = uploadImageUrl
      submitData.content.banners[name].img = url
    }

    const payload = {
      id: data?.id,
      gameCode: 'ash_echoes',
      countryCode: auth.data?.region
    } as ILauncher

    const content = {
      ...submitData.content,
      educationalVideoImage: submitData.content.educationalVideoImage || '',
      news: NewsArrToObj(submitData.content.news)
    } as ILauncherContent

    if (data?.id) {
      await UpdateContent(data?.id.toString(), content as ILauncherContent)
        .then((res: IResponse<ILauncher>) => {
          if (res.data?.success) {
            message.success({
              content: 'Launcher has been updated'
            })
            GetDetail(data.id)
          }
        })
        .finally(() => setLoading(false))
    } else
      Create({
        ...payload,
        content: submitData
      })
        .then((res: IResponse<ILauncher>) => {
          if (res.data?.success) {
            message.success({
              content: 'Launcher has been created'
            })
            setTimeout(() => {
              history.push('/launcher')
            }, 300)
          }
        })
        .finally(() => setLoading(false))
  }

  const defaultValue = {
    ...launcher.data,
    content: {
      ...launcher.data?.content,
      news: launcher.data?.content?.news
        ? (NewsObjToArr(launcher.data?.content?.news || {}) as any)
        : []
    }
  } as ILauncher

  const [formNews] = Form.useForm()

  return (
    <Layout
      header={{
        title: RoutesLabel.Launcher,
        url: '/launcher'
      }}
      breadcrumbs={
        id ? [{ label: launcher.data?.id ? 'Edit' : 'Create' }] : undefined
      }
    >
      {id ? (
        <DefaultForm
          initialValues={defaultValue}
          formName='form-launcher'
          fields={LauncherFormField}
          onCancel={() => {}}
          layout='horizontal'
          rowProps={{
            gutter: 16
          }}
          colProps={{
            span: 12
          }}
          form={formNews}
          onFinish={(e) => onSubmit(e)}
          additionalFieldForm={
            <>
              <Typography className='mb-1 text-weight-semibold'>
                News
              </Typography>
              <Form.List name={['content', 'news']}>
                {(fields, { add, remove }) => (
                  <div
                    style={{
                      display: 'flex',
                      rowGap: 16,
                      flexDirection: 'column'
                    }}
                  >
                    {fields.map((field, key) => (
                      <Card
                        size='small'
                        title={`News ${field.name + 1}`}
                        key={field.key}
                        extra={
                          <CloseOutlined
                            onClick={() => {
                              remove(field.name)
                            }}
                          />
                        }
                      >
                        <Form.Item
                          label='Name'
                          name={[field.name, 'name']}
                          rules={[
                            { required: true, message: 'Name is required' }
                          ]}
                        >
                          <Input />
                        </Form.Item>

                        {/* Nest Form.List */}
                        <Form.Item label='List'>
                          <Form.List name={[field.name, 'list']}>
                            {(subFields, subOpt) => (
                              <div
                                className='news-launcher-item'
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  rowGap: 16
                                }}
                              >
                                {subFields.map((subField) => (
                                  <Row
                                    key={subField.key}
                                    align='middle'
                                    className='w-100'
                                    gutter={8}
                                  >
                                    <Col flex={'1 1'}>
                                      <Space style={{ width: '100%' }}>
                                        <Form.Item
                                          noStyle
                                          name={[subField.name, 'crtime']}
                                          rules={[
                                            {
                                              required: true,
                                              message: 'Date is required'
                                            }
                                          ]}
                                        >
                                          <DatePicker className='w-100' />
                                        </Form.Item>
                                        <Form.Item
                                          noStyle
                                          name={[subField.name, 'title']}
                                          rules={[
                                            {
                                              required: true,
                                              message: 'Title is required'
                                            }
                                          ]}
                                        >
                                          <Input placeholder='Title' />
                                        </Form.Item>
                                        <Form.Item
                                          noStyle
                                          name={[subField.name, 'url']}
                                          rules={[
                                            {
                                              required: true,
                                              message: 'Url is required'
                                            }
                                          ]}
                                        >
                                          <Input placeholder='Url' />
                                        </Form.Item>
                                      </Space>
                                    </Col>
                                    <Col>
                                      <CloseOutlined
                                        onClick={() => {
                                          subOpt.remove(subField.name)
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                ))}
                                {subFields.length < 3 && (
                                  <Button
                                    type='dashed'
                                    onClick={() => subOpt.add()}
                                    block
                                  >
                                    + Add Sub Item
                                  </Button>
                                )}
                              </div>
                            )}
                          </Form.List>
                        </Form.Item>
                      </Card>
                    ))}
                    {fields.length < 4 && (
                      <Button type='primary' ghost onClick={() => add()} block>
                        + Add Item
                      </Button>
                    )}
                  </div>
                )}
              </Form.List>
            </>
          }
          additionalElementForm={
            <Row justify='end' className='mt-2'>
              <Col xs={24} md={8}>
                <ButtonAction
                  level={auth.ruleActivePage.level}
                  action={data?.id ? USER_ACTION.UPDATE : USER_ACTION.CREATE}
                  type='primary'
                  size='large'
                  htmlType='submit'
                  block
                  loading={loading}
                >
                  Save
                </ButtonAction>
              </Col>
            </Row>
          }
        />
      ) : (
        <LauncherList
          auth={auth}
          launcher={launcher}
          GetList={GetList}
          Delete={Delete}
        />
      )}
    </Layout>
  )
}

const mapStateToProps = (state: ReduxState) => ({
  launcher: state.launcher,
  auth: state.auth
})

const mapDispatchToProps = (dispatch: any) => ({
  Upload: (data: IUploadRequest) => dispatch(Actions.Upload.Create(data)),
  Create: (data: ILauncher) => dispatch(Actions.Launcher.Create(data)),
  GetDetail: (id: number) => dispatch(Actions.Launcher.GetDetail(id)),
  UpdateContent: (id: string, data: ILauncherContent) =>
    dispatch(Actions.Launcher.UpdateContent(id, data)),
  GetList: (
    gameCode?: string,
    countryCode?: string,
    page?: number,
    limit?: number
  ) => dispatch(Actions.Launcher.GetList(gameCode, countryCode, page, limit)),
  Delete: (id: number) => dispatch(Actions.Launcher.Delete(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(LauncherPage)
